import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import avatar from "./avatarnuevo.png";
import Moment from "moment";
import axios, { AxiosRequestConfig } from "./axios-config";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { FaPhoneSquareAlt } from "react-icons/fa";
import zxcvbn from "zxcvbn";
import AppCentral from "./AppCentral.js";
import Select from "react-select";
import logo2 from "./logo2.jpg";
import pols from "./pol.png";
import rec from "./reclamosgm.png";
import pag from "./pagos.jpg";
import ase from "./ase.png";
import all from "./all.png";
import a1 from "./aseguradoras/1.png";
import a2 from "./aseguradoras/2.png";
import a3 from "./aseguradoras/3.jpeg";
import a6 from "./aseguradoras/6.png";
import a7 from "./aseguradoras/7.png";
import a8 from "./aseguradoras/8.png";
import a10 from "./aseguradoras/10.jpg";
import a11 from "./aseguradoras/11.png";
import a12 from "./aseguradoras/12.jpg";
import a13 from "./aseguradoras/13.png";
import a28 from "./aseguradoras/28.png";
import a630 from "./aseguradoras/630.png";
import Poliza from "./poliza.js";
import PolizaDanos from "./polizadanos.js";
import Asesor from "./asesor.js";
import gestiones from "./gestiones.js";
import fs from "fs";
import XlsxPopulate from "xlsx-populate";
import gestion_reclamo from "./gestion_reclamo.js";
import bitacora_reclamo from "./bitacora_Reclamo.js";
import TablaCuenta from "./TablaCuenta.js";
import { saveAs } from "file-saver";
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";

import {
  EReCaptchaV2Size,
  EReCaptchaV2Theme,
  ReCaptchaProvider,
  ReCaptchaV2,
} from "react-recaptcha-x";
import $ from "jquery";
import { DesEncriptar } from "./Util/Encripcion";

class Forms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: "",
      imag: all,
      info_cliente: [],
      informacion: [],
      idCliente: window.idCliente,
      pnamACT: window.pnombre,
      snamACT: window.psegundonombre,
      papeACT: window.pprimerapellido,
      sapeACT: window.psegundoapellido,
      emACT: window.pemail,
      direACT: window.pdireccion,
      telACT: window.ptelefono,
      telACT2: window.ptelefono,
      regexp: 999999,
      selectedOptionRam: null,
      selectedOptionit: null,
      contra: "",
      monedaPol: "",
      tipPoliza: "",
      validacion: "",
      show: false,
      name: "React",
      showHideDemo1: false,
      showHideDemo2: false,
      showHideDemo3: false,
      handleShow1: false,
      showResults: false,
      modal: false,
      large: false,
      small: false,
      primary: false,
      success: false,
      warning: false,
      danger: false,
      info: false,
      type: "password",
      typeModal: "password",
      typeModal3: "password",
      collapse: true,
      valDanos: 0,
      datospoliza: [],
      datosramos: [],
      datosit: [],
      score: "null",
      fadeIn: true,
      cliente: "",
      poli: "",
      tipPo: "",
      idRam: "",
      tipRam: "",
      titulo: "",
      ventana: "",
      timeout: 300,
    };
    this.toggle1 = this.toggle1.bind(this);
    this.toggleFade1 = this.toggleFade1.bind(this);
    this._reCaptchaRef = React.createRef();
    this.handleChangeRam = this.handleChangeRam.bind(this);
    this.getSheetData = this.getSheetData.bind(this);
    this.handleGenerate2 = this.handleGenerate2.bind(this);
    this.handleGenerate = this.handleGenerate.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleChangeTi = this.handleChangeTi.bind(this);
  }

  getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  async handleGenerate() {
    let header2 = [
      "Asegurado",
      "Aseguradora",
      "Codigo Cliente",
      "Número Póliza",
      "Tipo Póliza",
      "Vigencia Fin",
      "Vigencia Inicio",
    ];
    let datos = [];
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/listadoPolizas/` +
          $("#idclienteF").val(),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        }
      )
      .then((json) => {
        if (json.status === 200) {
          if (json.data.informacion.informacionPolizas.length <= "1") {
            datos.push(json.data.informacion.informacionPolizas);            
            XlsxPopulate.fromBlankAsync().then(async (workbook) => {
              const sheet1 = workbook.sheet(0);
              const sheetData = this.getSheetData(datos, header2);
              const totalColumns = sheetData[0].length;

              sheet1.cell("A1").value(sheetData);
              const range = sheet1.usedRange();
              const endColumn = String.fromCharCode(64 + totalColumns);
              sheet1.row(1).style("bold", true);
              sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
              range.style("border", true);
              return workbook.outputAsync().then((res) => {
                saveAs(
                  res,
                  "ListadoPolizas_" + $("#idclienteF").val() + ".xlsx"
                );
              });
            });
          } else {
            XlsxPopulate.fromBlankAsync().then(async (workbook) => {
              const sheet1 = workbook.sheet(0);
              const sheetData = this.getSheetData(
                json.data.informacion.informacionPolizas,
                header2
              );
              const totalColumns = sheetData[0].length;

              sheet1.cell("A1").value(sheetData);
              const range = sheet1.usedRange();
              const endColumn = String.fromCharCode(64 + totalColumns);
              sheet1.row(1).style("bold", true);
              sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
              range.style("border", true);
              return workbook.outputAsync().then((res) => {
                saveAs(
                  res,
                  "ListadoPolizas_" + $("#idclienteF").val() + ".xlsx"
                );
              });
            });
          }
        } else {
          swal("Error", "datos,", "warning");
        }
      })
      .catch(function (error) {});
  }

  async handleGenerate2() {
    const aMoneda = (numero, opciones) => {
      // Valores por defecto
      opciones = opciones || {};
      opciones.simbolo = opciones.simbolo || "$";
      opciones.separadorDecimal = opciones.separadorDecimal || ".";
      opciones.separadorMiles = opciones.separadorMiles || ",";
      opciones.numeroDeDecimales =
        opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
      opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
      const CIFRAS_MILES = 3;

      // Redondear y convertir a cadena
      var num = parseFloat(numero);

      let numeroComoCadena = num.toFixed(opciones.numeroDeDecimales);

      // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
      let posicionDelSeparador = numeroComoCadena.indexOf(
        opciones.separadorDecimal
      );
      if (posicionDelSeparador === -1)
        posicionDelSeparador = numeroComoCadena.length;
      let formateadoSinDecimales = "",
        indice = posicionDelSeparador;
      // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
      while (indice >= 0) {
        let limiteInferior = indice - CIFRAS_MILES;
        // Agregar separador si cortamos más de 3
        formateadoSinDecimales =
          (limiteInferior > 0 ? opciones.separadorMiles : "") +
          numeroComoCadena.substring(limiteInferior, indice) +
          formateadoSinDecimales;
        indice -= CIFRAS_MILES;
      }
      let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(
        posicionDelSeparador,
        opciones.numeroDeDecimales + 1
      )}`;
      return opciones.posicionSimbolo === "i"
        ? opciones.simbolo + formateadoSinSimbolo
        : formateadoSinSimbolo + opciones.simbolo;
    };
    // Probar
    const opcionesQuetzales = {
        numeroDeDecimales: 2,
        separadorDecimal: ".",
        separadorMiles: ",",
        simbolo: "Q ", // Con un espacio, ya que la función no agrega espacios
        posicionSimbolo: "i", // i = izquierda, d = derecha
      },
      opcionesDolares = {
        numeroDeDecimales: 2,
        separadorDecimal: ".",
        separadorMiles: ",",
        simbolo: " USD", // Con un espacio, ya que la función no agrega espacios
        posicionSimbolo: "d", // i = izquierda, d = derecha
      };

    let header2 = [
      "Ramo",
      "Poliza",
      "Nombre",
      "Pago",
      "Factura",
      "Prima",
      "Vencimiento",
    ];
    let datos = [];
    var clienteReporte = $("#idclienteF").val();
    var esAsazgua = false;
    var tieneDatos = false;
    var urlGet = $("#idclienteF").val();
    if ($("#hdEsAzasgua").val() == "S") {
      clienteReporte = $("#hdClienteActual").val();
      esAsazgua = true;
      urlGet = $("#idepol").val() + "/" + $("#hdClienteActual").val();
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/estadoPendiente/` +
          urlGet,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        }
      )
      .then((json) => {
        var facturas = 0;
        if (json.status === 200) {
          if (json.data.informacion.facturasClientesWeb.length <= "1") {
            if (
              !esAsazgua ||
              (esAsazgua &&
                json.data.informacion.facturasClientesWeb.numeroPoliza ==
                  $("#hdNumPolAzasgua").val() &&
                json.data.informacion.facturasClientesWeb.codigoCliente ==
                  $("#hdClienteActual").val())
            ) {
              if (json.data.informacion.facturasClientesWeb.estado == "V") {
                datos.push(json.data.informacion.facturasClientesWeb);
                facturas++;
              }
              if (facturas > 0) {
                XlsxPopulate.fromBlankAsync().then(async (workbook) => {
                  const sheet1 = workbook.sheet(0);
                  const sheetData = this.getSheetData(datos, header2);
                  const totalColumns = sheetData[0].length;
                  sheet1.cell("A1").value(sheetData);
                  const range = sheet1.usedRange();
                  const endColumn = String.fromCharCode(64 + totalColumns);
                  sheet1.row(1).style("bold", true);
                  sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
                  range.style("border", true);
                  return workbook.outputAsync().then((res) => {
                    saveAs(
                      res,
                      "FacturasPendientes_" + clienteReporte + ".xlsx"
                    );
                  });
                });
              }
            } else {
              swal("Error", "No se encontraron datos", "warning");
            }
          } else {
            XlsxPopulate.fromBlankAsync().then(async (workbook) => {
              for (var i = 0; i < json.data.informacion.facturasClientesWeb.length; i++) {
                if (json.data.informacion.facturasClientesWeb[i].estado == "V") {
                  if (
                    !esAsazgua ||
                    (esAsazgua &&
                      json.data.informacion.facturasClientesWeb[i].numeroPoliza ==
                        $("#hdNumPolAzasgua").val() &&
                      json.data.informacion.facturasClientesWeb[i].codigoCliente ==
                        $("#hdClienteActual").val())
                  ) {
                    if (
                      json.data.informacion.facturasClientesWeb.codigoMoneda == 1 ||
                      json.data.informacion.facturasClientesWeb.codigoMoneda == "1"
                    ) {
                      datos.push({
                        TIPOPOLIZA: json.data.informacion.facturasClientesWeb[i].tipoPoliza,
                        NUMEROPOLIZA:
                          json.data.informacion.facturasClientesWeb[i].numeroPoliza,
                        NOMBRE: json.data.informacion.facturasClientesWeb[i].nombre,
                        NUMEROPAGO: json.data.informacion.facturasClientesWeb[i].numeroPago,
                        FACTURA: json.data.informacion.facturasClientesWeb[i].numeroFactura,
                        PRIMA: aMoneda(
                          json.data.informacion.facturasClientesWeb[i].prima,
                          opcionesQuetzales
                        ),
                        VENCIMIENTO: Moment(
                          json.data.informacion.facturasClientesWeb[i].fechaDeVencimiento
                        ).format("L"),
                      });
                      datos.push({
                        TIPOPOLIZA: json.data.informacion.facturasClientesWeb[i].tipoPoliza,
                        NUMEROPOLIZA:
                          json.data.informacion.facturasClientesWeb[i].numeroPoliza,
                        NOMBRE: json.data.informacion.facturasClientesWeb[i].nombre,
                        NUMEROPAGO: json.data.informacion.facturasClientesWeb[i].numeroPago,
                        FACTURA: json.data.informacion.facturasClientesWeb[i].numeroFactura,
                        PRIMA: aMoneda(
                          json.data.informacion.facturasClientesWeb[i].prima,
                          opcionesDolares
                        ),
                        VENCIMIENTO: Moment(
                          json.data.informacion.facturasClientesWeb[i].fechaDeVencimiento
                        ).format("L"),
                      });
                    } else {
                      if (
                        json.data.informacion.facturasClientesWeb[i].codigoMoneda == 1 ||
                        json.data.informacion.facturasClientesWeb[i].codigoMoneda == "1"
                      ) {
                        datos.push({
                          TIPOPOLIZA:
                            json.data.informacion.facturasClientesWeb[i].tipoPoliza,
                          NUMEROPOLIZA:
                            json.data.informacion.facturasClientesWeb[i].numeroPoliza,
                          NOMBRE: json.data.informacion.facturasClientesWeb[i].nombre,
                          NUMEROPAGO:
                            json.data.informacion.facturasClientesWeb[i].numeroPago,
                          FACTURA:
                            json.data.informacion.facturasClientesWeb[i].numeroFactura,
                          PRIMA: aMoneda(
                            json.data.informacion.facturasClientesWeb[i].prima,
                            opcionesQuetzales
                          ),
                          VENCIMIENTO: Moment(
                            json.data.informacion.facturasClientesWeb[i].fechaDeVencimiento
                          ).format("L"),
                        });
                      } else {
                        datos.push({
                          TIPOPOLIZA:
                            json.data.informacion.facturasClientesWeb[i].tipoPoliza,
                          NUMEROPOLIZA:
                            json.data.informacion.facturasClientesWeb[i].numeroPoliza,
                          NOMBRE: json.data.informacion.facturasClientesWeb[i].nombre,
                          NUMEROPAGO:
                            json.data.informacion.facturasClientesWeb[i].numeroPago,
                          FACTURA:
                            json.data.informacion.facturasClientesWeb[i].numeroFactura,
                          PRIMA: aMoneda(
                            json.data.informacion.facturasClientesWeb[i].prima,
                            opcionesDolares
                          ),
                          VENCIMIENTO: Moment(
                            json.data.informacion.facturasClientesWeb[i].fechaDeVencimiento
                          ).format("L"),
                        });
                      }
                    }
                    tieneDatos = true;
                  }
                }
              }
              if (tieneDatos) {
                const sheet1 = workbook.sheet(0);
                const sheetData = this.getSheetData(datos, header2);
                const totalColumns = sheetData[0].length;
                sheet1.cell("A1").value(sheetData);
                const range = sheet1.usedRange();
                const endColumn = String.fromCharCode(64 + totalColumns);
                sheet1.row(1).style("bold", true);
                sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
                range.style("border", true);
                return workbook.outputAsync().then((res) => {
                  saveAs(res, "FacturasPendientes_" + clienteReporte + ".xlsx");
                });
              } else {
                swal("Aviso", "No se encontraron datos", "info");
              }
            });
          }
        } else {
          swal("Aviso", "No se encontraron datos", "info");
        }
      })
      .catch(function (error) {});
  }

  componentDidMount() {
    swal(
      "Se está consultando la información de tus pólizas, por favor espera un momento",
      {
        buttons: false,
        timer: 2000,
      }
    );
    var offset = Moment().utcOffset();
    let fecha_val = $("#hidefecnac").val();
    var localText = Moment.utc(fecha_val).utcOffset(offset).format("L LT");
    let abre_asegu = "";
    var esAsazgua = false;
    if ($("#hdEsAzasgua").val() == "S") {
      esAsazgua = true;
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/polData/` +
          $("#hidedpi").val() +
          "/" +
          Moment.utc(fecha_val).format("YYYY-MM-DD"),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        }
      )
      .then((json) => {
        //console.log("Valor de polizas en : ", json.data.polizas)
        if (json.data.polizas.length >= 0) {
          for (var i = 0; i < json.data.polizas.length; i++) {
            if (
              !esAsazgua ||
              (esAsazgua &&
                json.data.polizas[i].polizasPK.numeroPoliza ==
                  $("#hdNumPolAzasgua").val())
            ) {
              this.setState({
                aseguradora: json.data.polizas[i].polizasPK.codigoAseguradora,
              });
              if (json.data.polizas[i].polizasPK.codigoAseguradora == "1") {
                abre_asegu = "GRAL";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "2"
              ) {
                abre_asegu = "SG&T";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "3"
              ) {
                abre_asegu = "ROBLE";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "470"
              ) {
                abre_asegu = "BMI";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "6"
              ) {
                abre_asegu = "ASSA";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "7"
              ) {
                abre_asegu = "CIGNA";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "15"
              ) {
                abre_asegu = "MAPFR";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "9"
              ) {
                abre_asegu = "SG&T";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "10"
              ) {
                abre_asegu = "PALIG";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "11"
              ) {
                abre_asegu = "AGRO";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "13"
              ) {
                abre_asegu = "UNIV";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "16"
              ) {
                abre_asegu = "AFGRA";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "17"
              ) {
                abre_asegu = "AFG&T";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "18"
              ) {
                abre_asegu = "AFRBL";
              } else if (
                json.data.polizas[i].polizasPK.codigoAseguradora == "50"
              ) {
                abre_asegu = "ASESU";
              } else {
                abre_asegu = "ND";
              }
              this.state.datosramos.push({
                poli: json.data.polizas[i].polizasPK.numeroPoliza,
                rm: json.data.polizas[i].polizasPK.codigoTipoPoliza,
                id: json.data.polizas[i].polizasPK.codigoAseguradora,
                certi: json.data.polizas[i].idepol,
                value: json.data.polizas[i].idepol,
                vCli: json.data.polizas[i].codigoCliente,
                mone: json.data.polizas[i].codigoMoneda.simbolo,
                label:
                  json.data.polizas[i].polizasPK.codigoAseguradora +
                  "-" +
                  abre_asegu +
                  "-" +
                  json.data.polizas[i].polizasPK.numeroPoliza,
                  ejecutivoEncargado:json.data.polizas[i].ejecutivoEncargado
              });

              this.state.datospoliza.push({
                id: json.data.polizas[i].polizasPK.codigoAseguradora,
                certi: json.data.polizas[i].idCertificado,
                mone: json.data.polizas[i].codigoMoneda.simbolo,
                vCli: json.data.polizas[i].codigoCliente,
                value: json.data.polizas[i].polizasPK.codigoTipoPoliza,
                label:
                  json.data.polizas[i].polizasPK.codigoTipoPoliza +
                  "-" +
                  json.data.polizas[i].polizasPK.codigoTipoPoliza,
              });
            }
          }
        } else {
          this.setState({
            aseguradora: json.data.polizas.polizasPK.codigoAseguradora,
          });
          if (json.data.polizas.polizasPK.codigoAseguradora == "1") {
            abre_asegu = "GRAL";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == "2") {
            abre_asegu = "SG&T";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == "3") {
            abre_asegu = "ROBLE";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == "470") {
            abre_asegu = "BMI";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == "6") {
            abre_asegu = "ASSA";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == "7") {
            abre_asegu = "CIGNA";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == "15") {
            abre_asegu = "MAPFR";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == "9") {
            abre_asegu = "SG&T";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == "10") {
            abre_asegu = "PALIG";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == "11") {
            abre_asegu = "AGRO";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == "13") {
            abre_asegu = "UNIV";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == "16") {
            abre_asegu = "AFGRA";
          } else if (json.data.polizas[i].polizasPK.codigoAseguradora == "17") {
            abre_asegu = "AFG&T";
          } else if (json.data.polizas[i].polizasPK.codigoAseguradora == "18") {
            abre_asegu = "AFRBL";
          } else if (json.data.polizas[i].polizasPK.codigoAseguradora == "50") {
            abre_asegu = "ASESU";
          } else {
            abre_asegu = "ND";
          }

          this.state.datosramos.push({
            poli: json.data.polizas.polizasPK.numeroPoliza,
            cl: json.data.polizas.codigoCliente,
            rm: json.data.polizas.polizasPK.codigoTipoPoliza,
            id: json.data.polizas.polizasPK.codigoAseguradora,
            certi: json.data.polizas.idepol,
            value: json.data.polizas.idepol,
            mone: json.data.polizas.codigoMoneda.simbolo,
            label:
              abre_asegu +
              "-" +
              json.data.polizas.polizasPK.codigoTipoPoliza +
              "-" +
              json.data.polizas.polizasPK.numeroPoliza,
              ejecutivoEncargado:json.data.polizas.ejecutivoEncargado
          });

          this.state.datospoliza.push({
            id: json.data.polizas.polizasPK.codigoAseguradora,
            certi: json.data.polizas.idepol,
            value: json.data.polizas.polizasPK.codigoTipoPoliza,
            label:
              json.data.polizas.polizasPK.codigoTipoPoliza +
              "-" +
              json.data.polizas.polizasPK.codigoTipoPoliza,
            mone: json.data.polizas.codigoMoneda.simbolo,
          });
        }
      });
    var esAsazgua = false;
    if ($("#hdEsAzasgua").val() == "S") {
      esAsazgua = true;
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/informacion/` +
          $("#hidedpi").val() +
          "/" +
          Moment.utc(fecha_val).format("YYYY-MM-DD"),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        }
      )
      .then((json) => {
        if (json.status === 200) {
          let pri_nombre = json.data.primerNombre
            ? json.data.primerNombre
            : json.data.razonSocial;
          let seg_nombre = json.data.segundoNombre
            ? json.data.segundoNombre
            : " ";
          window.infoCliente = pri_nombre;
          $("#idclienteF").val(json.data.idCliente);
          if (json.data.idCliente == 2391) {
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/tipos/` +
                  $("#idclienteF").val(),
                {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                      "jwtToken"
                    )}`,
                  },
                }
              )
              .then((json) => {
                if (json.data.clientesTipoPoliza.length > 1) {
                  for (
                    var i = 0;
                    i < json.data.clientesTipoPoliza.length;
                    i++
                  ) {
                    this.state.datosit.push({
                      label: json.data.clientesTipoPoliza[i].nombre,
                      value: json.data.clientesTipoPoliza[i].tipo,
                    });
                  }
                } else {
                  this.state.datosit.push({
                    tipRam: json.data.clientesTipoPoliza.nombre,
                    idRam: json.data.clientesTipoPoliza.tipo,
                  });
                }
              })
              .catch(function (error) {});
          } else {
            if (!esAsazgua) {
              //console.log("No es asazgua: ",  json.data.primerNombre +" " +seg_nombre +" " +json.data.primerApellido +" " +json.data.segundoApellido);
              this.setState({
                cliente:
                  json.data.primerNombre +
                  " " +
                  seg_nombre +
                  " " +
                  json.data.primerApellido +
                  " " +
                  json.data.segundoApellido,
              });
            }
          }
          setTimeout(function () {
            window.datos = json.data;
          }, 9000);
        } else {
          swal(
            "Error",
            "datos, si no cuentas con usuario puedes crearlo",
            "warning"
          );
        }
      })
      .catch(function (error) {});

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/reclamo/` +
          $("#hidedpi").val() +
          "/" +
          Moment.utc(fecha_val).format("YYYY-MM-DD"),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        }
      )
      .then((json) => {
        if (json.status === 200) {
         // console.log("REQUEST 2");
          if (json.data.tPolCertificado.length >= 0) {
          //  console.log("AQui: 2");
            $("#simple").val(0);
            for (var i = 0; i < json.data.tPolCertificado.length; i++) {
              this.state.datosramos.push({
                poli: json.data.tPolCertificado[i].idPoliza.numeroPoliza,
                rm: json.data.tPolCertificado[i].idPoliza.idTipoPoliza
                  .codigoTipoPoliza,
                id: json.data.tPolCertificado[i].idPoliza.idAseguradora,
                certi: json.data.tPolCertificado[i].idCertificado,
                value: json.data.tPolCertificado[i].idPoliza.idPoliza,
                label:
                  json.data.tPolCertificado[i].idPoliza.idAseguradora
                    .abreviatura +
                  "-" +
                  json.data.tPolCertificado[i].idPoliza.idTipoPoliza
                    .codigoTipoPoliza +
                  "-" +
                  json.data.tPolCertificado[i].idPoliza.numeroPoliza,
              });
              this.state.datospoliza.push({
                id: json.data.tPolCertificado[i].idPoliza.idAseguradora,
                certi: json.data.tPolCertificado[i].idCertificado,
                value: json.data.tPolCertificado[i].idPoliza.idTipoPoliza,
                label:
                  json.data.tPolCertificado[i].idPoliza.idTipoPoliza
                    .codigoTipoPoliza +
                  "-" +
                  json.data.tPolCertificado[i].idPoliza.idTipoPoliza
                    .descripcion,
              });
              /*console.log(
                "json.data.tPolCertificado[0].idCertificado: ",
                json.data.tPolCertificado[0].idCertificado
              );*/
              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/cliente/` +
                    json.data.tPolCertificado[0].idCertificado,
                  {
                    headers: {
                      Authorization: `Bearer ${sessionStorage.getItem(
                        "jwtToken"
                      )}`,
                    },
                  }
                )
                .then((json2) => {
                  //  $('#certificado').val(json.data.tPolCertificado[0].idCertificado);
                  //          $('#idclienteF').val(window.idCliente);
                  let seg_nombre = json.data.segundoNombre
                    ? json.data.segundoNombre
                    : " ";
                  this.setState({
                    cliente:
                      json2.data.primerNombre +
                      " " +
                      seg_nombre +
                      " " +
                      json2.data.primerApellido +
                      " " +
                      json2.data.segundoApellido,
                  });
                });

              this.setState({
                aseguradora:
                  json.data.tPolCertificado[i].idPoliza.idAseguradora
                    .idAseguradora,
              });
            }
          } else {
            $("#simple").val(1);
            this.setState({
              aseguradora:
                json.data.tPolCertificado.idPoliza.idAseguradora.idAseguradora,
            });
            this.setState({
              poli: json.data.tPolCertificado.idPoliza.numeroPoliza,
            });
            this.state.datosramos.push({
              rm: json.data.tPolCertificado.idPoliza.idTipoPoliza
                .codigoTipoPoliza,
              certi: json.data.tPolCertificado.idCertificado,
              id: json.data.tPolCertificado.idPoliza.idAseguradora
                .idAseguradora,
              value: json.data.tPolCertificado.idPoliza.idPoliza,
              label:
                json.data.tPolCertificado.idPoliza.idAseguradora.abreviatura +
                "-" +
                json.data.tPolCertificado.idPoliza.idTipoPoliza
                  .codigoTipoPoliza +
                "-" +
                json.data.tPolCertificado.idPoliza.numeroPoliza,
            });
            this.state.datospoliza.push({
              certi: json.data.tPolCertificado.idCertificado,
              id: json.data.tPolCertificado.idPoliza.idAseguradora
                .idAseguradora,
              value: json.data.tPolCertificado.idPoliza.idTipoPoliza,
              label:
                json.data.tPolCertificado.idPoliza.idTipoPoliza
                  .codigoTipoPoliza +
                "-" +
                json.data.tPolCertificado.idPoliza.idTipoPoliza.descripcion,
            });

            fetch(
              `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/cliente/` +
                json.data.tPolCertificado.idCertificado,
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
                },
              }
            )
              .then((res) => res.json())
              .then((data) => {
                $("#certificado").val(json.data.tPolCertificado.idCertificado);
                //  $('#idclienteF').val(window.idCliente);
                let seg_nombre = json.data.segundoNombre
                  ? json.data.segundoNombre
                  : " ";
                this.setState({
                  cliente:
                    data.primerNombre +
                    " " +
                    seg_nombre +
                    " " +
                    data.primerApellido +
                    " " +
                    data.segundoApellido,
                });
              });
          }
        } else {
          //swal("Error", "Validando informacion General", "warning");
        }
      });
    //}
  }

  handleChangeTi = (selectedOptionit) => {
    this.setState({ tipPoliza: selectedOptionit.label });
    this.setState({ tipPolizaCodigo: selectedOptionit.value });
    this.state.datosramos = [];
    $("#tipoPol").val(selectedOptionit.value);
    swal(
      "Se estan consultando tu información general, por favor espera un momento",
      {
        buttons: false,
        timer: 2000,
      }
    );
    let fecha_val = $("#hidefecnac").val();
    //var localText = Moment.utc(fecha_val).utcOffset(offset).format("L LT");
    let abre_asegu = "";

    $("#id_ram").val(selectedOptionit.value);
    $("#tip_ram").val(selectedOptionit.label);

    this.setState({ idRam: selectedOptionit.value });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/polData/` +
          $("#hidedpi").val() +
          "/" +
          Moment.utc(fecha_val).format("YYYY-MM-DD"),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        }
      )
      .then((json) => {
        if (json.data.polizas.length >= 0) {
          for (var i = 0; i < json.data.polizas.length; i++) {
            this.setState({
              aseguradora: json.data.polizas[i].polizasPK.codigoAseguradora,
            });
            if (json.data.polizas[i].polizasPK.codigoAseguradora == 1) {
              abre_asegu = "GRAL";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 2) {
              abre_asegu = "SG&T";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 3) {
              abre_asegu = "ROBLE";
            } else if (
              json.data.polizas[i].polizasPK.codigoAseguradora == 470
            ) {
              abre_asegu = "BMI";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 6) {
              abre_asegu = "ASSA";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 7) {
              abre_asegu = "CIGNA";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 15) {
              abre_asegu = "MAPFR";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 9) {
              abre_asegu = "SG&T";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 10) {
              abre_asegu = "PALIG";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 11) {
              abre_asegu = "AGRO";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 13) {
              abre_asegu = "UNIV";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 16) {
              abre_asegu = "AFGRA";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 17) {
              abre_asegu = "AFG&T";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 18) {
              abre_asegu = "AFRBL";
            } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 50) {
              abre_asegu = "ASESU";
            } else {
              abre_asegu = "ND";
            }

            if (
              selectedOptionit.value ==
              json.data.polizas[i].polizasPK.codigoTipoPoliza
            ) {
              this.state.datosramos.push({
                poli: json.data.polizas[i].polizasPK.numeroPoliza,
                rm: json.data.polizas[i].polizasPK.codigoTipoPoliza,
                id: json.data.polizas[i].polizasPK.codigoAseguradora,
                certi: json.data.polizas[i].idepol,
                value: json.data.polizas[i].idepol,
                vCli: json.data.polizas[i].codigoCliente,
                mone: json.data.polizas[i].codigoMoneda.simbolo,
                label:
                  json.data.polizas[i].polizasPK.codigoAseguradora +
                  "-" +
                  abre_asegu +
                  "-" +
                  json.data.polizas[i].polizasPK.numeroPoliza,
                  ejecutivoEncargado:json.data.polizas[i].ejecutivoEncargado
              });
            }

            this.state.datospoliza.push({
              id: json.data.polizas[i].polizasPK.codigoAseguradora,
              certi: json.data.polizas[i].idCertificado,
              mone: json.data.polizas[i].codigoMoneda.simbolo,
              vCli: json.data.polizas[i].codigoCliente,
              value: json.data.polizas[i].polizasPK.codigoTipoPoliza,
              label:
                json.data.polizas[i].polizasPK.codigoTipoPoliza +
                "-" +
                json.data.polizas[i].polizasPK.codigoTipoPoliza,
            });
          }
        } else {
          this.setState({
            aseguradora: json.data.polizas.polizasPK.codigoAseguradora,
          });
          if (json.data.polizas.polizasPK.codigoAseguradora == 1) {
            abre_asegu = "GRAL";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == 2) {
            abre_asegu = "SG&T";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == 3) {
            abre_asegu = "ROBLE";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == 470) {
            abre_asegu = "BMI";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == 6) {
            abre_asegu = "ASSA";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == 7) {
            abre_asegu = "CIGNA";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == 15) {
            abre_asegu = "MAPFR";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == 9) {
            abre_asegu = "SG&T";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == 10) {
            abre_asegu = "PALIG";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == 11) {
            abre_asegu = "AGRO";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == 13) {
            abre_asegu = "UNIV";
          } else if (json.data.polizas.polizasPK.codigoAseguradora == 16) {
            abre_asegu = "AFGRA";
          } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 17) {
            abre_asegu = "AFG&T";
          } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 18) {
            abre_asegu = "AFRBL";
          } else if (json.data.polizas[i].polizasPK.codigoAseguradora == 50) {
            abre_asegu = "ASESU";
          } else {
            abre_asegu = "ND";
          }

          this.state.datosramos.push({
            poli: json.data.polizas.polizasPK.numeroPoliza,
            cl: json.data.polizas.codigoCliente,
            rm: json.data.polizas.polizasPK.codigoTipoPoliza,
            id: json.data.polizas.polizasPK.codigoAseguradora,
            certi: json.data.polizas.idepol,
            value: json.data.polizas.idepol,
            mone: json.data.polizas.codigoMoneda.simbolo,
            label:
              abre_asegu +
              "-" +
              json.data.polizas.polizasPK.codigoTipoPoliza +
              "-" +
              json.data.polizas.polizasPK.numeroPoliza,
              ejecutivoEncargado:json.data.polizas.ejecutivoEncargado
          });

          this.state.datospoliza.push({
            id: json.data.polizas.polizasPK.codigoAseguradora,
            certi: json.data.polizas.idepol,
            value: json.data.polizas.polizasPK.codigoTipoPoliza,
            label:
              json.data.polizas.polizasPK.codigoTipoPoliza +
              "-" +
              json.data.polizas.polizasPK.codigoTipoPoliza,
            mone: json.data.polizas.codigoMoneda.simbolo,
          });
        }
      });
  };

  handleChangeRam = (selectedOptionRam) => {
    console.log("Valor de selectedOptionRam.value: ", selectedOptionRam);
   // console.log("$(#hdEsAzasgua).val()", $("#hdEsAzasgua").val());
   // console.log('$("#hdClienteActual").val()', $("#hdClienteActual").val());
   sessionStorage.setItem("ejecutivoEncargado",selectedOptionRam.ejecutivoEncargado);
    let valRam;
    let clienteSel =
      $("#hdEsAzasgua").val() == "S"
        ? $("#hdClienteActual").val()
        : selectedOptionRam.vCli;
   // console.log("clienteSel: ", clienteSel);
    $("#polizaCompleta").val(selectedOptionRam.label);
    $("#idepol").val(selectedOptionRam.value);
    $("#idclienteU").val(selectedOptionRam.vCli);
    if (selectedOptionRam.vCli) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/estado/` +
            $("#idepol").val() +
            "/" +
            clienteSel,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
            },
          }
        )
        .then((json) => {
          if (json.status === 200) {
            if (json.data.facturasClientesWeb.length > 0) {
              this.setState({
                cliente: json.data.facturasClientesWeb[0].nombre,
              });
            } else {
              this.setState({
                cliente: json.data.facturasClientesWeb.nombre,
              });
            }
          } else {
            swal("Error", "No se encontro información de  Poliza", "warning");
          }
        })
        .catch(function (error) {
          //swal("Atención", "La póliza no cuenta con facturas pendientes ni tampoco pagadas Inicio", "warning");
        });
    }
    this.setState({ numeroPoliza: selectedOptionRam.poli });
    this.setState({ monedaPol: selectedOptionRam.mone });
    switch (selectedOptionRam.rm) {
      case "GM":
        this.setState({ tipPoliza: "Póliza de Gastos Medicos" });
        this.setState({ tipPolizaCodigo: "GM" });
        break;
      case "VD":
        this.setState({ tipPoliza: "Póliza de Vida" });
        this.setState({ tipPolizaCodigo: "VD" });
        break;
      case "ACP":
        this.setState({ tipPoliza: "Póliza de Accidentes Personales" });
        this.setState({ tipPolizaCodigo: "ACP" });
        break;
      case "INCEN":
        this.setState({ tipPoliza: "Póliza de Incendio" });
        this.setState({ tipPolizaCodigo: "INCEN" });
        break;
      case "RC":
        this.setState({ tipPoliza: "Póliza de Responsabilidad Civil" });
        this.setState({ tipPolizaCodigo: "RC" });
        break;
      case "TDINCEN":
        this.setState({ tipPoliza: "Póliza de Todo Riesgo" });
        this.setState({ tipPolizaCodigo: "TDINCEN" });
        break;
      case "CRI":
        this.setState({ tipPoliza: "Póliza de Crimen" });
        this.setState({ tipPolizaCodigo: "CRI" });
        break;
      case "FA":
        this.setState({ tipPoliza: "Póliza de Fianzas" });
        this.setState({ tipPolizaCodigo: "FA" });
        break;
      case "CERT":
        this.setState({ tipPoliza: "Póliza de Certificados" });
        this.setState({ tipPolizaCodigo: "CERT" });
        break;
      case "EE":
        this.setState({ tipPoliza: "Póliza de Equipo Electronico" });
        this.setState({ tipPolizaCodigo: "EE" });
        break;
      case "MM":
        this.setState({ tipPoliza: "Póliza de Maquinaria" });
        this.setState({ tipPolizaCodigo: "MM" });
        break;
      case "PD":
        this.setState({ tipPoliza: "Póliza Dental" });
        this.setState({ tipPolizaCodigo: "PD" });
        break;
      case "TR":
        this.setState({ tipPoliza: "Póliza De Transporte" });
        this.setState({ tipPolizaCodigo: "TR" });
        break;
      case "VA":
        this.setState({ tipPoliza: "Póliza de Vehiculos" });
        this.setState({ tipPolizaCodigo: "VA" });
        break;

      default:
        this.setState({ tipPoliza: "ND" });
    }

    let registro_aseguradora = 0;
    if (
      selectedOptionRam.rm === "GM" ||
      selectedOptionRam.rm === "PD" ||
      selectedOptionRam.rm === "ACP" ||
      selectedOptionRam.rm === "VD"
    ) {
      this.setState({ valDanos: 0 });

      $("#asegu").val(selectedOptionRam.id.idAseguradora);

      $("#certificado").val(selectedOptionRam.certi);
      this.setState({ selectedOptionRam });

      valRam = selectedOptionRam.value;

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/cartera/` +
            valRam,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
            },
          }
        )
        .then((json3) => {          
          //console.log("json3.data.idCartera: ",json3.data.idCartera);
          if (json3.data.idCartera !== 3) {
            //console.log("ingrese a individual");
            $("#cuenta").removeClass();
            $("#poliza").removeClass();
            $("#gestiones").removeClass();
            $("#asesor").removeClass();
            $("#cuenta").show();
            $("#gestiones").show();
            $("#cuenta").addClass("col-xs-12 col-md-3");
            $("#poliza").addClass("col-xs-12 col-md-2");
            $("#gestiones").addClass("col-xs-12 col-md-2");
            $("#asesor").addClass("col-xs-12 col-md-2");
          } else {
            //console.log("ingrese a colectivos");
            $("#cuenta").removeClass();
            $("#poliza").removeClass();
            $("#gestiones").removeClass();
            $("#infoasegu").removeClass();
            $("#asesor").removeClass();
            $("#cuenta").hide();
            $("#gestiones").show();
            $("#poliza").addClass("col-xs-12 col-md-3");
            $("#infoasegu").addClass("col-xs-12 col-md-3");
            $("#gestiones").addClass("col-xs-12 col-md-3");
            $("#asesor").addClass("col-xs-12 col-md-3");
          }
        });
        //console.log('$("#simple").val() ',$("#simple").val());
      if ($("#simple").val() === "0") {
        //console.log("ingreso a simple");
        registro_aseguradora = selectedOptionRam.id.idAseguradora;
        //console.log("ingreso a simple" + registro_aseguradora);
      } else {
        registro_aseguradora = this.state.aseguradora;
        //console.log("ingreso a normal" + registro_aseguradora);
      }

      //console.log("registro_aseguradora: ", registro_aseguradora);

      $("#ag").empty();
      $("#ag").append("Aseguradora");
      //console.log("registro_aseguradora: ", String(registro_aseguradora));
      switch (String(registro_aseguradora)) {
        case "1":
          this.setState({
            imag: a1,
          });
          return $("#asegus")
            .attr("href", "https://www.aseguradorageneral.com/formularios")
            .attr("target", "_blank");

        case "2":          
          this.setState({
            imag: a2,
          });
          
          return $("#asegus")
            .attr(
              "href",
              "https://segurosgyt.com.gt/descargas"
            )
            .attr("target", "_blank");
            
        case "3":
          this.setState({
            imag: a3,
          });
          
          return $("#asegus")
            .attr("href", "https://www.elroble.com/guias-y-formularios/")
            .attr("target", "_blank");            
        case "6":
          this.setState({
            imag: a6,
          });          
          return $("#asegus")
            .attr(
              "href",
              "https://www.assanet.com.gt/reclamos/formularios/"
            )
            .attr("target", "_blank");

        case "7":
          this.setState({
            imag: a7,
          });          
          return $("#asegus")
            .attr(
              "href",
              "https://www.mapfre.com.gt/formularios-gastos-medicos/"
            )
            .attr("target", "_blank");

        case "8":
          this.setState({
            imag: a8,
          });          
          return $("#asegus")
            .attr(
              "href",
              "https://www.ficohsa.com/seguros/formularios/formularios-banco-ficohsa/"
            )
            .attr("target", "_blank");

        case "13":
          this.setState({
            imag: a13,
          });          
          return $("#asegus")
            .attr("href", "https://www.universales.com/")
            .attr("target", "_blank");

        case "28":
          this.setState({
            imag: a28,
          });
          
          return $("#asegus")
            .attr("href", "https://bmi.gt/descargas/formularios/")
            .attr("target", "_blank");

        case "12":
          this.setState({
            imag: a12,
          });          
          return $("#asegus")
            .attr("href", "https://www.aseguate.com/index.php")
            .attr("target", "_blank");

        case "10":
          this.setState({
            imag: a10,
          });          
          return $("#asegus")
            .attr("href", "https://www.palig.com/es/guatemala/home/forms")
            .attr("target", "_blank");

        case "11":
          //console.log("agro");
          this.setState({
            imag: a11,
          });          
          return $("#asegus")
            .attr("href", "https://www.bam.com.gt/seguros-agromercantil/")
            .attr("target", "_blank");
        case "16":
          //console.log("agro");
          this.setState({
            imag: a1,
          });          
          return $("#asegus")
            .attr("href", "https://www.aseguradorageneral.com/formularios")
            .attr("target", "_blank");

        case "630":
          this.setState({
            imag: a630,
          });          
          return $("#asegus")
            .attr(
              "href",
              "https://www.bupasalud.com.gt/individuos/centro-de-bienvenida/clientes-individuales/documentos-de-poliza"
            )
            .attr("target", "_blank");

        default:
          return "hola";
      }
      $("#btnEmergencia").hide();
    } else {
      //  $('#idclienteF').val(selectedOptionRam.cl);
      this.setState({ valDanos: 1 });
      $("#certificado").val(selectedOptionRam.certi);
      $("#monedaPol").val(selectedOptionRam.mone);
      this.setState({ selectedOptionRam });
      //console.log(selectedOptionRam.certi);
      //console.log(selectedOptionRam.mone);
      valRam = selectedOptionRam.value;
      $("#asegu").val(selectedOptionRam.id);
      //console.log(selectedOptionRam.id);
      $("#cuenta").removeClass();
      $("#infoasegu").removeClass();
      $("#poliza").removeClass();
      $("#gestiones").removeClass();
      $("#asesor").removeClass();
      $("#cuenta").show();
      $("#gestiones").hide();
      $("#infoasegu").addClass("col-xs-12 col-md-3");
      $("#cuenta").addClass("col-xs-12 col-md-3");
      $("#poliza").addClass("col-xs-12 col-md-3");
      $("#asesor").addClass("col-xs-12 col-md-3");

      //console.log("ingreso a simple");
      registro_aseguradora = selectedOptionRam.id;
      //console.log("ingreso a simple" + registro_aseguradora);

      $("#asegus").removeAttr("href");
      $("#phEmergencia").removeAttr("href");

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/infoConAseg/` +
            $("#asegu").val(),
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
            },
          }
        )
        .then((json) => {
          if (json.status === 200) {
            $("#phEmergencia").attr(
              "href",
              "tel:502" +
                json.data.telefonoCabDanos.replace("-", "").substring(0, 8)
            );
            $("#btnEmergencia").show();
          } else {
            swal(
              "Error",
              "No se encontro información de Aseguradora",
              "warning"
            );
          }
        })
        .catch(function (error) {
          $("#btnEmergencia").hide();
          //    swal("Error", "No se cuenta con teléfono de la Aseguradora", "warning");
        });

      //console.log("ingrese a vehiculos" + registro_aseguradora);
      $("#ag").empty();
      $("#ag").append("Gestión Reclamo");
      switch (String(registro_aseguradora)) {
        case "1":
          this.setState({
            imag: a1,
          });

          return $("#asegus").attr("onClick", "this.showModal(5)");

        case "2":
          this.setState({
            imag: a2,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");

        case "3":
          this.setState({
            imag: a3,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");

        case "6":
          this.setState({
            imag: a6,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");

        case "7":
          this.setState({
            imag: a7,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");

        case "8":
          this.setState({
            imag: a8,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");

        case "13":
          this.setState({
            imag: a13,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");
        case "28":
          this.setState({
            imag: a28,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");

        case "12":
          this.setState({
            imag: a12,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");

        case "10":
          this.setState({
            imag: a10,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");

        case "11":
          //console.log("agro");
          this.setState({
            imag: a11,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");
        case "16":
          //console.log("agro");
          this.setState({
            imag: a1,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");
        case "16":
          //console.log("fianza roble");
          this.setState({
            imag: a1,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");
        case "17":
          //console.log("fianza gyt");
          this.setState({
            imag: a2,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");
        case "18":
          //console.log("fianza roble");
          this.setState({
            imag: a3,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");
        case "630":
          this.setState({
            imag: a630,
          });
          //break;
          return $("#asegus").attr("onClick", "this.showModal(5)");

        default:
          return "hola";
      }
    }
  };

  showModal = (valor) => {
    var registroU = 0;
    var registroF = 0;
    //console.log("llegue:::" + $("#idclienteU").val());
    if ($("#idclienteU").val() == "" || $("#idclienteU").val() == undefined) {
      registroU = 1;
    }

    if ($("#idclienteF").val() == "" || $("#idclienteF").val() == undefined) {
      registroF = 1;
    }

    if (
      (registroU == 1 || registroU == undefined) &&
      (registroF == 1 || registroF == undefined)
    ) {
      swal(
        "Atención",
        "Para consultar algún dato debes de seleccionar previamente una póliza.",
        "warning"
      );
    } else {
      if (valor === 1) {
        $("#datGen").hide();
        $("#datGen").hide();
        if (this.state.valDanos === 0) {
          this.setState({ titulo: "Información General" });
          this.setState({ ventana: Poliza });
          this.setState({ show: true });
        } else {
          this.setState({ titulo: "Información General" });
          this.setState({ ventana: PolizaDanos });
          this.setState({ show: true });
        }
      } else if (valor === 2) {
        $("#datGen").hide();
        $("#datGen").hide();
        this.setState({ titulo: "Información Asesores" });
        this.setState({ ventana: Asesor });
        this.setState({ show: true });
      } else if (valor === 4) {
        $("#datGen").hide();
        $("#datGen").hide();
        this.setState({ titulo: "Gestiones Digitales" });
        this.setState({ ventana: gestiones });
        this.setState({ show: true });
      } else if (valor === 5) {
        $("#datGen").hide();
        $("#datGen").hide();
        this.setState({ titulo: "Información Reclamos" });
        //this.setState({ ventana: gestion_reclamo });
        this.setState({ ventana: gestion_reclamo });
        //this.setState({ ventana: bitacora_reclamo });

        if (
          $("#tipoPol").val() === "VA" ||
          $("#tipoPol").val() === "CERT" ||
          $("#tipoPol").val() === "MM" ||
          $("#tipoPol").val() === "EE" ||
          $("#tipoPol").val() === "TR" ||
          $("#tipoPol").val() === "INCEN" ||
          $("#tipoPol").val() === "TDINCEN" ||
          $("#tipoPol").val() === "CRI" ||
          $("#tipoPol").val() === "FA" ||
          $("#tipoPol").val() === "RC"
        ) {
          this.setState({ show: true });
        } else {
          this.setState({ show: false });
        }
      } else {
        $("#datGen").hide();
        $("#datGen").hide();
        this.setState({ titulo: "Información Estado de Cuenta" });
        this.setState({ ventana: TablaCuenta });
        this.setState({ show: true });
      }
    }
  };

  hideModal = () => {
    this.setState({ show: false });
    $("#valPrin").val(2);
  };

  toggle1() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  toggleFade1() {
    this.setState((prevState) => {
      return {
        fadeIn: !prevState,
      };
    });
  }

  render() {
    const pol = this.state.datospoliza;
    const { selectedOptionRam } = this.state;
    const { selectedOptionit } = this.state;
    const pol_ram = this.state.datosramos;
    const itip = this.state.datosit;
    return (
      <div>
        <Modal size="xl" show={this.state.show} onHide={this.hideModal}>
          <Modal.Header
            style={{ background: "#003479", color: "white" }}
            closeButton
          >
            <Modal.Title>{this.state.titulo}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="datGen" name="datGen" style={{ display: "none" }}>
              <h3>Suma Asegurada:</h3>{" "}
              <Input type="text" id="monto" name="monto" disabled />
              <h3>Vigencia de Poliza:</h3>{" "}
              <Input type="text" id="vigPol" name="vigPol" disabled />
            </div>

            <this.state.ventana />
          </Modal.Body>
        </Modal>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <Card>
              <CardHeader
                style={{
                  background: "#003479",
                  color: "#ffffff",
                }}
              >
                <h2>
                  <strong>
                    Selecciona una póliza para gestionar su información
                  </strong>
                </h2>
              </CardHeader>
              <CardBody
                style={{
                  background: "#003479",
                }}
                className="card-body text-danger"
              >
                <Row>
                  <div className="row">
                    <br></br>
                    <Col
                      style={{
                        color: "#000000",
                      }}
                      xs="12"
                      md="3"
                    >
                      <Select
                        id="rer"
                        name="rer"
                        options={itip}
                        value={selectedOptionit}
                        onChange={this.handleChangeTi}
                      />
                    </Col>
                    <Col
                      style={{
                        color: "#000000",
                      }}
                      xs="12"
                      md="3"
                    >
                      <Select
                        id="pol"
                        name="pol"
                        options={pol_ram}
                        value={selectedOptionRam}
                        onChange={this.handleChangeRam}
                      />
                    </Col>
                    <br></br>
                    <Col
                      style={{
                        color: "#000000",
                      }}
                      xs="12"
                      md="3"
                    >
                      <Input
                        type="text"
                        id="ram"
                        name="ram"
                        placeholder="Tipo Poliza"
                        value={this.state.tipPoliza}
                        disabled="disabled"
                      />
                    </Col>

                    <br></br>
                    <Col xs="12" md="3">
                      <Input
                        type="text"
                        id="disabled-input"
                        name="disabled-input"
                        placeholder="Titular"
                        value={this.state.cliente}
                        disabled="disabled"
                      />
                    </Col>
                  </div>
                  <Input type="hidden" id="idepol" name="idepol" />
                  <Input type="hidden" id="simple" name="simple" />
                  <Input type="hidden" id="asegu" name="asegu" />
                  <Input type="hidden" id="idRam" name="asegu" />
                  <Input type="hidden" id="tipRam" name="asegu" />
                  <Input type="hidden" id="valPrin" name="valPrin" />
                  <Input type="hidden" id="certificado" name="certificado" />
                  <Input type="hidden" id="idclienteF" name="idclienteF" />
                  <Input type="hidden" id="idclienteU" name="idclienteU" />
                  <Input
                    type="hidden"
                    id="dato_deducible"
                    name="dato_deducible"
                  />
                  <Input type="hidden" id="dato_cob" name="dato_cob" />
                  <Input
                    type="hidden"
                    id="tipoPol"
                    name="tipoPol"
                    value={this.state.tipPolizaCodigo}
                  />
                  <Input
                    type="hidden"
                    id="numeroPol"
                    name="numeroPol"
                    value={this.state.numeroPoliza}
                  />
                  <Input type="hidden" id="monedaPol" name="monedaPol" />
                  <Input
                    type="hidden"
                    id="polizaCompleta"
                    name="polizaCompleta"
                  />
                </Row>
                <br></br>
                <Row>
                  <Col xs="12" md="6" id="dv_infoPoliza">
                    <h5 style={{ color: "#FFFFFF" }}>
                      <strong>
                        Descarga información de tus pólizas{" "}
                        <u
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleGenerate()}
                        >
                          AQUI
                        </u>
                      </strong>
                    </h5>
                  </Col>
                  <Col xs="12" md="6">
                    <h5 style={{ color: "#FFFFFF" }}>
                      {" "}
                      <strong>
                        Descarga facturas pendientes de pago{" "}
                        <u
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleGenerate2()}
                        >
                          AQUI
                        </u>
                      </strong>
                    </h5>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="row">
          <hr style={{ background: "#872434", size: 10, color: "#872434" }} />
          <br></br>
          <br></br>
          <div className="col-sm-12 col-md-4" id="infoasegu" name="infoasegu">
            <Card>
              <CardImg width="400px" height="175px" src={this.state.imag} />
              <CardFooter
                style={{
                  background: "#575a5d",
                  color: "#ffffff",
                }}
              >
                <u>
                  {" "}
                  <a
                    style={{
                      color: "#ffffff",
                    }}
                    onClick={() => this.showModal(5)}
                    id="asegus"
                    name="asegus"
                  >
                    <strong>
                      <Label id="ag" name="ag"></Label>
                    </strong>
                  </a>
                </u>
              </CardFooter>
            </Card>
          </div>

          <div className="col-xs-12 col-sm-4" id="poliza" name="poliza">
            <Card>
              <CardImg width="400px" height="175px" src={pols} />
              <CardFooter
                style={{
                  background: "#575a5d",
                  color: "#ffffff",
                }}
              >
                <u>
                  <a onClick={() => this.showModal(1)}>
                    <strong>Póliza</strong>
                  </a>
                </u>
              </CardFooter>
            </Card>
          </div>
          <div className="col-xs-12 col-sm-4" id="asesor" name="asesor">
            <Card>
              <CardImg width="400px" height="175px" src={ase} />
              <CardFooter
                style={{
                  background: "#575a5d",
                  color: "#ffffff",
                }}
              >
                <strong>
                  <u>
                    <a onClick={() => this.showModal(2)}>Asesor</a>
                  </u>
                </strong>
              </CardFooter>
            </Card>
          </div>
          <div
            className="col-xs-12 col-md-3"
            id="gestiones"
            name="gestiones"
            style={{ display: "none" }}
          >
            <Card>
              <CardImg width="400px" height="175px" src={rec} />
              <CardFooter
                style={{
                  background: "#575a5d",
                  color: "#ffffff",
                }}
              >
                <strong>
                  <a
                    style={{
                      color: "#ffffff",
                      cursor: "pointer",
                    }}
                    href="https://reclamos.somit.com"
                    target="_blank"
                  >
                    Gestiones
                  </a>
                </strong>
              </CardFooter>
            </Card>
          </div>
          <div
            className="col-xs-12 col-md-3"
            id="cuenta"
            name="cuenta"
            style={{ display: "none" }}
          >
            <Card>
              <CardImg width="400px" height="175px" src={pag} />
              <CardFooter
                style={{
                  background: "#575a5d",
                  color: "#ffffff",
                }}
              >
                <strong>
                  <u>
                    <a onClick={() => this.showModal(3)}>Estado de Cuenta</a>
                  </u>
                </strong>
              </CardFooter>
            </Card>
          </div>
        </div>
        <br></br> <br></br>
        <div
          id="btnEmergencia"
          name="btnEmergencia"
          style={{ display: "none" }}
        >
          <Button
            style={{
              background: "#003479",
              color: "#ffffff",
            }}
            size="lg"
          >
            <FaPhoneSquareAlt size={60} />{" "}
            <strong>
              <a
                style={{ background: "#003479", color: "white" }}
                id="phEmergencia"
                name="phEmergencia"
              >
                Cabina de Emergencia
              </a>
            </strong>
          </Button>
        </div>
      </div>
    );
  }
}

export default Forms;
